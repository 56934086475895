import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
import "./element-variables.scss";
import "./plugins/svgIcon.js";
import "@/assets/icons";
import "@/assets/css/global.css";
import "@/assets/css/main.css";
import "@/assets/css/icon.css";
import "@/assets/css/color-dark.css";
import Viewer from "v-viewer";
import "./date/date.js";
import "./utils/util.js";
import "viewerjs/dist/viewer.css";
// import * as echarts from 'echarts'
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

import exifr from "exifr";
import { GetUser } from "./axios/axios";

import "./map/popup.css";
import "./map/overview.css";
import "ol/ol.css";

Vue.use(exifr);
Vue.prototype.Exifr = exifr;

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);
Vue.use(Viewer);
Viewer.setDefaults({
  inline: true,
  button: true, //右上角按钮
  navbar: true, //底部缩略图
  title: true, //当前图片标题
  toolbar: true, //底部工具栏
  tooltip: true, //显示缩放百分比
  movable: true, //是否可以移动
  zoomable: true, //是否可以缩放
  rotatable: true, //是否可旋转
  scalable: true, //是否可翻转
  transition: true, //使用 CSS3 过度
  fullscreen: true, //播放时是否全屏
  keyboard: true, //是否支持键盘
  url: "data-source",
  ready: function (e) {
    console.log(e.type, "组件以初始化");
  },
  show: function (e) {
    console.log(e.type, "图片显示开始");
  },
  shown: function (e) {
    console.log(e.type, "图片显示结束");
  },
  hide: function (e) {
    console.log(e.type, "图片隐藏完成");
  },
  hidden: function (e) {
    console.log(e.type, "图片隐藏结束");
  },
  view: function (e) {
    console.log(e.type, "视图开始");
  },
  viewed: function (e) {
    console.log(e.type, "视图结束");
    // 索引为 1 的图片旋转20度
    if (e.detail.index === 1) {
      this.viewer.rotate(20);
    }
  },
  zoom: function (e) {
    console.log(e.type, "图片缩放开始");
  },
  zoomed: function (e) {
    console.log(e.type, "图片缩放结束");
  },
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  //window.document.title=to.meta.title;
  if (to.meta.requireAuth == true) {
    //需要登录权限进入的路由
    if (store.state.token != null && store.state.token != "") {
      //取到登录信息
      GetUser(store.state.token)
        .then((res) => {
          console.log(res.data);
          next();
        })
        .catch((err) => {
          console.log(err);
          next({ name: "LoginRegister" });
        });
    } else if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("token") != ""
    ) {
      //得到登录信息，进行下一步
      GetUser(localStorage.getItem("token"))
        .then((res) => {
          console.log(res.data);
          next();
        })
        .catch((err) => {
          console.log(err);
          next({ name: "LoginRegister" });
        });
    } else {
      next({ name: "LoginRegister" });
    }
  } else {
    //不需要登录权限
    next();
  }
});
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
